import * as React from "react";
import type {
  HeadFC,
  GetServerDataReturn,
  PageProps,
  GetServerDataProps,
} from "gatsby";

import { useWeb3Provider, Web3Providers } from "../contexts/AppContext";

import { NavBar } from "../components/NavBar/NavBar";

import { PageHeader } from "../components/PageHeader/PageHeader";
import { Blog, BlogPostType } from "../services/Blog";
import { BlogPostItem } from "../components/BlogPostItem/BlogPostItem";
import { GoHomeButton } from "../components/Items/Buttons/Buttons";
import { DashboardNavbar } from "../components/DashboardNavbar/DashboardNavbar";
import { SEO } from "../components/SEO";

const IndexPageComponent = ({ serverData }: { serverData: BlogPostType[] }) => {
  const web3Provider = useWeb3Provider();
  return (
    <Web3Providers>
      <main className="blog-page">
        {web3Provider.accountDetails?.address ? (
          <DashboardNavbar />
        ) : (
          <NavBar />
        )}

        <PageHeader
          title="Blog"
          subtitle="O ThePolakach zrobiło się już głośno. Media lubią pisać o naszej kolekcji NFT na polskiej scenie krypto."
        />

        <section className="blog-posts blog-view__section">
          <div className="blog-view__box">
            {serverData &&
              serverData.map((post: BlogPostType) => {
                return (
                  <BlogPostItem
                    Title={post.Title}
                    shortDescription={post.shortDescription}
                    imgUrl={post.imgUrl}
                    MediumUrl={post.MediumUrl}
                  />
                );
              })}
          </div>
          <GoHomeButton />
        </section>
      </main>
    </Web3Providers>
  );
};

export default IndexPageComponent;

// export const Head: HeadFC = () => <title>Blog</title>;
export const Head: HeadFC = () => <SEO subtitle="Blog" />;

export async function getServerData() {
  try {
    const response = await Blog.getBlogPosts();
    if (response instanceof Error) throw response;
    return { props: response };
  } catch (err) {
    console.error(err);
    return {};
  }
}
