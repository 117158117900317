import * as React from "react";
import { BlogPostType } from "../../services/Blog";
import "./BlogPostItem.scss";
// blog post
export const BlogPostItem = (blogPost: BlogPostType) => {
  return (
    <>
      {" "}
      <div className="item radius theshadow" data-value="1">
        <figure className="blog-post-item__figure">
          <img className="blog-post-item__image" src={blogPost.imgUrl} alt="" />
        </figure>
        <div className="blog-post-item__box content">
          <h3 className="blog-post-item__header">{blogPost.Title}</h3>
          <p className="blog-post-item__description">
            {blogPost.shortDescription}
          </p>
          <a
            href={blogPost.MediumUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="yellow-btn blog-post-item__button theshadow btn-clicked"
          >
            Przeczytaj
          </a>
        </div>
      </div>
    </>
  );
};
